import React, { Fragment, useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Layout from "../../Layout"
import Container from "../../Layout/Container"

import styles from "../../MedEnsure/Request/RequestComplete/utils/bookingComplete.module.scss"
import { useEnrollmentCompleteImages } from "./hooks/useEnrollmentCompleteImages"
import { handleSignOut } from "../../Auth/services/signout"
import { GATSBY_PULSECARE_EMAIL } from "gatsby-env-variables"

const EnrollmentComplete = ({ pageContext, location }) => {
  const { module, content, nextPath } = pageContext
  const data = useEnrollmentCompleteImages()

  useEffect(() => {
    handleSignOut({ redirect: false })
  }, [])

  return (
    <Layout
      title={module?.title || "Thank you!"}
      seoTitle={module?.seoTitle}
      pageContext={pageContext}
    >
      <Container isCentered>
        <div className={classNames("columns", styles["column"])}>
          {content?.icon && (
            <div className="column is-4-desktop is-12-mobile is-10-tablet">
              <figure className={classNames("image mx-3")}>
                <Img
                  fluid={data[content.icon].childImageSharp.fluid}
                  alt={`${module.name} Complete`}
                  className={styles["image"]}
                />
              </figure>
            </div>
          )}
          <div className="column">
            <Fragment>
              <p className="mb-2">
                A MedGrocer representative will be sending you a message. If you don't receive a message within the next working
                day, please contact us at{" "}
                <a
                  href={`mailto:${GATSBY_PULSECARE_EMAIL}?subject=Inquiry on Enrollment Form`}
                  className="has-text-weight-bold"
                >
                  {GATSBY_PULSECARE_EMAIL}
                </a>
                .
              </p>
            </Fragment>
          </div>
        </div>
        <center>
          <Link to={nextPath} className="mt-2 button is-primary is-medium">
            Finish
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default EnrollmentComplete
